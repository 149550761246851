<ng-container *ngIf="isGroupedQuestion">
  <div class="qus">
    <div class="text-nowrap me-4">
      {{ hideOrder ? "" : formTemplateQuestion.order + ". " }}
      <ng-container *ngTemplateOutlet="questionLabel; context: { ftq: formTemplateQuestion }" />
    </div>
    <ng-container *ngTemplateOutlet="questionType; context: { class: 'd-flex p-0' }" />
  </div>
  <div
    *ngIf="service.showErrors && formControl.invalid"
    class="invalid-feedback d-flex justify-content-end pe-2 pb-2"
  >
    Please answer this mandatory question
  </div>
</ng-container>

<ng-container *ngIf="!isGroupedQuestion && !isQuestionList(formTemplateQuestion)">
  <div *ngIf="!hideOrder" class="no-side">{{ formTemplateQuestion.order }}</div>
  <div class="lavel-side">
    <p>
      <ng-container *ngTemplateOutlet="questionLabel; context: { ftq: formTemplateQuestion }" />
    </p>
    <ng-container *ngTemplateOutlet="questionType" />
  </div>
</ng-container>

<ng-container *ngIf="!isGroupedQuestion && isQuestionList(formTemplateQuestion)">
  <div class="box">
    <div class="title d-flex">
      {{ hideOrder ? "" : formTemplateQuestion.order + ". "
      }}<ng-container *ngTemplateOutlet="questionLabel; context: { ftq: formTemplateQuestion }" />
    </div>
    <ng-container *ngTemplateOutlet="questionType" />
    <div
      *ngIf="service.showErrors && formControl.invalid"
      class="invalid-feedback d-flex px-2 pb-2"
    >
      Please answer this mandatory question
    </div>
  </div>
</ng-container>

<ng-template #questionType let-class="class">
  <ng-container *ngIf="isQuestionRange(formTemplateQuestion)">
    <app-question-range [class]="class" [question]="formTemplateQuestion" />
    <div *ngIf="service.showErrors && formControl.invalid" class="invalid-feedback d-flex">
      Please answer this mandatory question
    </div>
  </ng-container>
  <ng-container *ngIf="isQuestionList(formTemplateQuestion)">
    <app-question-list [class]="class" [question]="formTemplateQuestion" />
  </ng-container>
  <ng-container *ngIf="isQuestionText(formTemplateQuestion)">
    <app-question-text [class]="class" [question]="formTemplateQuestion" />
    <div *ngIf="service.showErrors && formControl.invalid" class="invalid-feedback d-flex">
      Please answer this mandatory question
    </div>
  </ng-container>
</ng-template>

<ng-template #questionLabel let-question="ftq">
  {{ question.value }}
  <span *ngIf="question.required" class="required-asterisk">*</span>
  <div class="ms-1 multi-line" [innerHTML]="question.note"></div>
</ng-template>
