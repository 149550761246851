export const environment = {
  production: true,
  region: "ap-southeast-2",
  endpoint: "https://api.qa.surveyor.digitalp.com.au",
  logLevel: "debug",
  buildId: process.env.NG_APP_BUILD_ID || "local",
  defaultCollectionLimit: 10,
  enableServiceWorker: true,
  enableSentry: true,
  sentryEnvironment: "qa",
  sentryTracesSampleRate: 1,
  sentryReplayMaskAllText: false,
  sentryReplayMaskAllInputs: false,
  sentryReplayBlockAllMedia: false,
  sentryReplaySessionSampleRate: 1,
  sentryReplayOnErrorSampleRate: 1,
  timeZone: "Australia/Brisbane",
  defaultAuthEmail: "",
  defaultAuthPassword: "",
  defaultDateFormat: "dd MMM yyyy hh:mma",
  defaultShortDateFormat: "dd/MM/yyyy",
  siteDomain: "qa.surveyor.digitalp.com.au",
};
